import React from "react"
import { Link, graphql } from "gatsby"
import { dateLarge } from "../../utils/Utils"
import Page from "../../components/page/Page"

export default props => {
  const opinionEdges = props.data.allContentfulOpinion.edges
  return (
    <Page class="categoria">
      <div className="list">
        <header className="list-header">
          <div className="subtitle">Todos los resultados para:</div>
          <h1 className="title">{props.pageContext.nombre}</h1>
        </header>
        <main className="list-main">
          {opinionEdges.map((edge, i) => (
            <Link key={i} className="item" to={`/opinion/${edge.node.slug}`}>
              <h2 className="item-title">{edge.node.titulo}</h2>
              <div className="item-date">{dateLarge(edge.node.fecha)}</div>
              <div className="item-excerpt">
                {edge.node.texto.childMarkdownRemark.excerpt}
              </div>
            </Link>
          ))}
        </main>
      </div>
    </Page>
  )
}

export const categoriasQuery = graphql`
  query categoriasQuery($nombre: String) {
    allContentfulOpinion(
      sort: { fields: fecha, order: DESC }
      filter: { categorias: { in: [$nombre] } }
    ) {
      edges {
        node {
          slug
          titulo
          fecha
          texto {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`
